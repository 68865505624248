import { v2Colors, getRgbaColor, breakpoints } from '@web-for-marketing/react-ui';

export const menuLinksStyle = {
    internalLinkContainer: {
        display: 'block',
        transition: 'background-color 100ms ease, color 100ms ease',
        borderTopRightRadius: 2,
        borderBottomRightRadius: 2,
        '&:hover p:first-child': {
            textDecoration: 'underline',
        },
        '&:hover': {
            backgroundColor: getRgbaColor(v2Colors.core.deepSpace, 0.02),
        },
        '&:focus p:first-child': {
            textDecoration: 'underline',
        },
        '&:focus': {
            backgroundColor: `${getRgbaColor(v2Colors.core.deepSpace, 0.05)} !important`,
        },
    },
    secondaryTextLink: {
        '&:hover': {
            color: v2Colors.core.slate,
            backgroundColor: `${getRgbaColor(v2Colors.core.deepSpace, 0.03)} !important`,
        },
        '&:focus': {
            color: v2Colors.core.slate,
            backgroundColor: `${getRgbaColor(v2Colors.core.deepSpace, 0.05)} !important`,
        },
    },
    accessibleDescriptionFocus: {
        '&:hover p:nth-child(2)': {
            color: v2Colors.core.slate,
        },
        '&:focus p:nth-child(2)': {
            color: v2Colors.core.slate,
        },
    },
    MenuLinksContainerSpacing: {
        padding: '0.4rem 0',
        margin: '0.2rem 0',
    },
    MobileMenuLinksContainerSpacing: {
        padding: '1.4rem 0',
        margin: '0.6rem 0',
    },
    MobileMenuLinksReducedContainerSpacing: {
        padding: '1rem 0',
        margin: '0.4rem 0',
    },
    MenuLinksAndDescriptionContainerSpacing: {
        padding: '0.6rem 0',
        margin: '0.4rem 0',
    },
    MenuLinksAndImageContainerSpacing: {
        padding: '0.4rem 0',
        margin: '0.2rem 0',
    },
    menuPageLinkText: {
        color: v2Colors.core.geotab,
        fontSize: '1.6rem',

        '& > *': {
            color: v2Colors.core.geotab,
        },
    },
    menuNoTitle: {
        '&:first-child': {
            marginTop: '3.8rem !important',
        },
        [`@media (max-width: ${breakpoints.xl})`]: {
            '&:first-child': {
                marginTop: '3.5rem',
            },
        },
        [`@media (max-width: ${breakpoints.lg})`]: {
            '&:first-child': {
                marginTop: '0',
            },
        },
    },
    primaryTextLink: {
        '& p': {
            color: v2Colors.core.geotab,
            fontSize: '1.6rem',
            display: 'inline-block',
        },
        '& > *': {
            color: v2Colors.core.geotab,
        },
    },
    primaryGroupTextLink: {
        marginTop: '2rem',
        '& p': {
            color: v2Colors.core.geotab,
            fontSize: '1.6rem',
        },
    },
    primaryBold: {
        '& p': {
            color: v2Colors.core.geotab,
            fontSize: '1.6rem',
            fontWeight: 600,
        },
    },
    primaryGroupTextLinkNoMargin: {
        '& p': {
            color: v2Colors.core.geotab,
            fontSize: '1.6rem',
        },
    },
    menuTitleSpacing: {
        '&:nth-child(2)': {
            marginTop: '0.4rem !important',
        },
    },
    twoColumnMobileContainer: {
        marginTop: '2rem !important',
        '&:first-child': {
            marginTop: '0 !important',
        },
    },
    primarySpacing: {
        marginTop: '1.4rem !important',
    },
    noMenuTitle: {
        display: 'block',
        height: '1.4rem',
        padding: '.35rem 0',
    },
    linkTextStyle: {
        '&:hover': {
            backgroundColor: 'transparent !important',
            textDecoration: 'none !important',
        },
        '& p': {
            color: v2Colors.core.innovation,
            padding: '0.5rem 1rem',
            margin: '1.4rem 0 1rem',
            transform: 'translateX(-1rem)',
            display: 'inline-block',
            fontWeight: 700,
            transition: 'color 500ms ease',
            '&:hover, &:hover > *': {
                color: v2Colors.core.geotab,
                transition: 'color 500ms ease',
                backgroundColor: `${getRgbaColor(v2Colors.core.deepSpace, 0.03)} !important`,
            },
            '&:focus, &:focus > *': {
                color: v2Colors.core.geotab,
                transition: 'color 500ms ease',
                backgroundColor: `${getRgbaColor(v2Colors.core.deepSpace, 0.05)} !important`,
            },
        },
    },
    bottomMedia: {
        position: 'absolute',
        height: '30rem',
        bottom: 0,
        objectFit: 'contain',
        width: 'auto',
        userSelect: 'none',
    },
};
