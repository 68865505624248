import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { Grid } from '@mui/material';
import { MenuLinks } from './MenuLinks';
import { v2Colors, breakpoints } from '@web-for-marketing/react-ui';

const classes = {
    twoColumnContainers: {
        paddingRight: '5rem',
        [`@media (max-width: ${breakpoints.lg})`]: {
            paddingBottom: '2rem',
        },
    },
    divider: {
        '&:last-child::after': {
            content: '""',
            height: '100%',
            width: '1px',
            display: 'block',
            position: 'absolute',
            transform: 'translateX(-2.5rem)',
            top: 0,
            backgroundColor: v2Colors.core.smoke,
            [`@media (max-width: ${breakpoints.lg})`]: {
                '&:last-child::after': {
                    display: 'none',
                },
            },
        },
    },
};

export function TwoColumnMenu({ columnsArray, hasDivider, noBreak, columnsPropsArray, closeMenu, previewState }) {
    const renderedColumns = useMemo(
        () =>
            Array.isArray(columnsArray) &&
            columnsArray.map((content, index) => {
                return (
                    <Grid
                        item
                        lg={6}
                        xs={noBreak ? 6 : 12}
                        css={[classes.twoColumnContainers, hasDivider ? classes.divider : undefined]}
                        key={index}
                    >
                        <MenuLinks
                            title={content.title}
                            menuItems={content.links}
                            image={content.image}
                            type={content.type}
                            closeMenu={closeMenu}
                            isGrouped={content.isGrouped}
                            {...columnsPropsArray[index]}
                            previewState={previewState}
                        />
                    </Grid>
                );
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }),
        [columnsArray, columnsPropsArray, hasDivider, noBreak, closeMenu, previewState]
    );

    return <Grid container>{renderedColumns}</Grid>;
}

TwoColumnMenu.propTypes = {
    columnsArray: PropTypes.arrayOf(
        PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.bool]))
    ),
    columnsPropsArray: PropTypes.arrayOf(PropTypes.object),
    noBreak: PropTypes.bool,
    closeMenu: PropTypes.func,
    hasDivider: PropTypes.bool,
    previewState: PropTypes.bool,
};

TwoColumnMenu.defaultProps = {
    columnsArray: [
        { content: {}, type: '' },
        { content: {}, type: '' },
    ],
    columnsPropsArray: [{}, {}],
    hasDivider: false,
    previewState: false,
};
