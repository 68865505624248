import { v2Colors, getRgbaColor, breakpoints } from '@web-for-marketing/react-ui';

export const singleColumnWithImageMenuStyle = {
    linkTextStyle: {
        color: v2Colors.core.innovation,
        padding: '0.5rem 1rem',
        marginBottom: '1rem',
        transform: 'translateX(-1rem)',
        display: 'inline-block',
        fontWeight: 700,
        transition: 'color 500ms ease',
        ' &:hover, &:hover > *': {
            color: v2Colors.core.geotab,
            transition: 'color 500ms ease',
            backgroundColor: `${getRgbaColor(v2Colors.core.deepSpace, 0.03)} !important`,
        },
        '&:focus, &:focus > *': {
            color: v2Colors.core.geotab,
            transition: 'color 500ms ease',
            backgroundColor: `${getRgbaColor(v2Colors.core.deepSpace, 0.05)} !important`,
        },
    },
    menuContainer: {
        paddingBottom: '24rem',
    },
    bottomMedia: {
        position: 'absolute',
        height: '24rem',
        bottom: 0,
        left: 0,
        objectFit: 'contain',
        width: '100%',
        userSelect: 'none',
        [`@media (max-width: ${breakpoints.lg})`]: {
            height: '20rem',
        },
    },
    contentMargin: {
        marginBottom: '2rem',
    },
    descriptionStyle: {
        maxWidth: '45rem',
    },
};
